<template>
    <!--begin::Card-->
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Sửa nhóm quyền.
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Sửa nhóm quyền</span>
            </div>
            <div class="card-toolbar">

                <router-link
                        class="font-weight-bold font-size-3  btn btn-secondary"
                        :to="{ name: 'role-list' }"
                ><i class="fas fa-angle-double-left"></i> Quay lại
                </router-link>
            </div>
        </div>
        <!--end::Header-->
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="" id="role-edit" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div id="input-group-2" role="group" class="form-group">
                            <label id="input-group-2__BV_label_"
                                   class="d-block">Tên nhóm quyền:</label>
                            <div>
                              <ValidationProvider name="Tên nhóm quyền" rules="required" vid="name" v-slot="{ errors,classes }">
                                <input type="text" ref="name" name="name" class="form-control" :class="classes"
                                       placeholder="Tên nhóm quyền"
                                       v-model=form.name>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div id="input-group-3" role="group" class="form-group">
                            <button class="btn btn-primary">Trang {{form.guard_name}}</button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label id=""
                               class="d-block">Quyền:</label>
                        <div class="accordion" role="tablist" v-if="getRoleById.guard_name!=='backend'">
                            <b-card no-body class="mb-1" :key="key" v-for="(item, key) in  getRoleById.guardPermissions">
                                <b-card-header header-tag="header" class="p-3" role="tab">
                                    <b-form-checkbox
                                            class="font-weight-bold"
                                            v-b-toggle="'accordion'+item.slug"
                                            v-model="item.checked"
                                            size="lg"
                                            name="permission"
                                            @input="checkAll(item)"
                                    >
                                        {{item.name}}
                                    </b-form-checkbox>
                                </b-card-header>
                                <b-collapse :id="'accordion'+item.slug" visible role="tabpanel">
                                    <b-card-body>
                                        <div class="checkbox-inline">
                                            <b-form-checkbox name="permission" class="col-md-3" :key="key" v-for="(child, key) in item.children" v-model="child.checked" size="lg">{{child.name}}</b-form-checkbox>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                      <div v-else>
                        <el-tree
                            :props="defaultProps"
                            :data="getRoleById.guardPermissions"
                            default-expand-all
                            node-key="id"
                            :default-checked-keys="getRoleById.permission_ids"
                            show-checkbox
                            @check-change="handleCheckChange"
                        >
                        </el-tree>
                      </div>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <button type="submit" :disabled="isDisable" class="btn btn-success">Submit</button>
            </div>
        </form>
      </ValidationObserver>
    </div>
    <!--end::Card-->
</template>

<script>
    import {GET_ROLE_BY_ID,UPDATE_ROLE,} from "../../../../core/services/store/user/role.module";
    import {mapGetters} from "vuex";
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    export default {
        name: "Edit-role",
        data() {
            return {
                id: '',
                form: {
                    name: "",
                    guard_name : ''
                },
              defaultProps: {
                label: 'name'
              },
              permission_selected : [],
              checked : true,
              isDisable: false,
            };
        },
        created() {
            this.id = this.$route.params.id;
            this.$store.dispatch(GET_ROLE_BY_ID, this.id).then((res)=> {
              this.form.name = res.data.name;
              this.form.guard_name = res.data.guard_name;
            });
        },
        computed: {
            ...mapGetters(['getRoleById','getPermissionsByGuard']),
            error: state => state.errors,
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Tài khoản", route: 'account'},
                {title: "Sửa nhóm quyền"}
            ]);
        },
        watch : {
          getRoleById(newValue,oldValue){
            if(newValue!==oldValue){
              this.permission_selected = newValue.permission_ids
            }
          }
        },
        methods: {
          handleCheckChange(data, checked, indeterminate) {
            if(this.permission_selected.includes(data.id)){
              if(!checked){
                this.permission_selected = this.permission_selected.filter(item => item !== data.id)
              }
            }
            if(!this.permission_selected.includes(data.id)){
              if(checked){
                this.permission_selected.push(data.id)
              }
            }
          },
            checkAll(item){
                item.children.map((child)=>{
                    child.checked = item.checked;
                })
            },
            onSubmit: function () {
                this.isDisable = true;
                let condition = _.reduceDeep( this.getRoleById.guardPermissions, (acc, value, key, parent, ctx) => {
                    if(key=='checked'&& value==true) acc.push(parent.id);
                    return acc;
                },[]);
                let payload = {
                    name: this.form.name,
                    permission: this.getRoleById.guard_name==='backend'?this.permission_selected:condition
                };

                this.$store.dispatch(UPDATE_ROLE, {
                    id: this.id,
                    params : payload
                }).then((res) => {
                    this.$router.push({name: "role-list"}).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Thành công',
                            variant: 'success',
                            solid: true
                        });
                    });
                }).catch((e) => {
                  if (e.data.data.message_validate_form){
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                  }
                }).finally(() => {
                  this.isDisable = false;
                })
            },
        },
    }
</script>

<style scoped></style>
